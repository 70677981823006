import { Injectable, inject } from '@angular/core';
import { AuthorizationReadOnlyControllerService } from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles/read';
import { LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly authorizationReadOnlyControllerService = inject(AuthorizationReadOnlyControllerService);

  hasPermission(permissionId: number): Observable<boolean> {
    const customerGuid = this.localStorageService.get<OAuthTokenResponse>(
      LocalStorageKey.OAuthTokenResponse
    )?.customerId;
    return this.authorizationReadOnlyControllerService
      .getContextAuthorization({ customerGuid: customerGuid, permissionId: permissionId })
      .pipe(
        switchMap((hasPermission): Observable<boolean> => {
          return hasPermission == null ? throwError(() => new Error('invalid permission id')) : of(hasPermission);
        })
      );
  }
}
