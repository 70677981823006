<div class="full-height">
  <div class="content">
    <fly-header
      [oem]="userConfig != null && userConfig.isOemUser"
      [dealer]="userConfig != null && userConfig.isDealerUser"
      [orgName]="orgName"
      [customer]="customer"
      [membershipCount]="membershipCount">
    </fly-header>

    <router-outlet></router-outlet>
  </div>

  <fly-footer
    [detectedLocale]="userConfig?.detectedLocale"
    [detectedCountry]="userConfig?.detectedCountry"
    [loggedIn]="userConfig != null">
  </fly-footer>

  <div id="consent_blackbar"></div>
  <div id="teconsent"></div>
</div>
