import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'fly-down-for-maintenance',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './down-for-maintenance.component.html',
  styleUrl: './down-for-maintenance.component.scss'
})
export class DownForMaintenanceComponent {}
