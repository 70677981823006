import { Injectable } from '@angular/core';
import { AbstractPromoCodeService } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService extends AbstractPromoCodeService {
  protected override url = `${environment.flyLegacyUrl}/api/v2/subscriptions/garmin-pilot/coupons/`;
}
