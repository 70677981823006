import { Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrgAccessControls } from '@garmin-avcloud/avcloud-fly-web-common/orgs';
import { Permission } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class OrgAccessControlsService implements OrgAccessControls {
  readonly canViewOrgSettings = signal(true);
  readonly canEditOrgSettings = signal(true);
  readonly canViewOrgMemberships = signal(true);
  readonly canCreateOrgMemberships = signal(true);
  readonly canDeleteOrgMemberships = signal(true);
  readonly canViewOrgMembershipInvites = signal(true);
  readonly canCreateOrgMembershipInvites = signal(true);
  readonly canDeleteOrgMembershipInvites = signal(true);
  readonly canViewOrgAircraft = signal(false);

  private readonly authorizationService = inject(AuthorizationService);

  constructor() {
    this.authorizationService
      .hasPermission(Permission.AIRCRAFT_READ)
      .pipe(takeUntilDestroyed())
      .subscribe((canViewOrgAircraft) => {
        this.canViewOrgAircraft.set(canViewOrgAircraft);
      });
  }
}
