import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TealiumUtagService } from '@garmin-avcloud/avcloud-web-utils';
import { environment } from '../environments/environment';

@Component({
  selector: 'fly-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TealiumUtagService]
})
export class AppComponent implements OnInit {
  title = 'flygarminapp';

  constructor(
    private readonly router: Router,
    private readonly tealium: TealiumUtagService
  ) {}

  ngOnInit(): void {
    this.tealium.setConfig(environment.tealiumConfig);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tealium.view({
          tealium_event: 'fly_garmin_page_load',
          page_type: event.urlAfterRedirects.toLowerCase(),
          page_name: (window as any).document.title
        });
      }
    });
  }
}
