import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Permission } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { Observable, tap } from 'rxjs';
import { AuthorizationService } from './authorization.service';

export const orgRouteGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  return inject(AuthorizationService)
    .hasPermission(Permission.ORGANIZATION_EDIT)
    .pipe(
      tap((response) => {
        if (!response) {
          router.navigate(['denied']);
        }
      })
    );
};
